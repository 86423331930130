<template>
  <b-container class="mt-3">
    <b-jumbotron class="card-border jbt">
      <PageTitle :title="title" />
      <div class="d-flex mb-3 text-info">
        <b-icon class="h4" icon="exclamation-circle-fill" variant="info"></b-icon>
        <div class="ml-3 mr-3">
          <div>Lista de espera aberta neste momento somente para crianças nascidas entre os anos de <strong>2012 a 2015</strong>.</div>
          <div>Não há vagas PCD no momento.</div>
        </div>
      </div>
      <ValidationObserver ref="observer">
        <b-form @submit.stop.prevent>
          <b-row>
            <b-col cols="md-6 sm-12">
              <ValidationProvider name="Data de Nascimento" rules="required" v-slot="validationContext">
                <b-form-group
                  id="input-group-2"
                  label="Data de nascimento"
                  label-for="birthDate"
                  class="mb-3"
                >
                  <b-form-input
                    id="birthDate"
                    v-model="form.birthDate"
                    type="date"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-2-live-feedback"
                    @blur="setAge(form.birthDate); rulesToRegister()"
                    @input="clearDeficiency()"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col cols="md-6 sm-12">
              <ValidationProvider name="Período Escolar" rules="required" v-slot="validationContext">
                <b-form-group
                  id="input-group-6"
                  label="Período escolar"
                  label-for="schoolTerm"
                  class="mb-3"
                  v-b-tooltip.hover
                  title="A vaga não pode ser ao mesmo tempo que o período escolar"
                >
                  <b-form-select
                    id="schoolTerm"
                    v-model="form.schoolTerm"
                    :options="schoolTerms"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-6-live-feedback"
                    @change="clearDeficiency()"
                    @input="setSchoolTerm(form.schoolTerm); rulesToRegister(); onClearPresetClassAndModality()"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="undefined" disabled>-- Selecione --</b-form-select-option>
                    </template>
                  </b-form-select>
                  <b-form-invalid-feedback id="input-6-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-6 sm-12">
              <ValidationProvider name="Deficiência" vid="deficiency" rules="required" v-slot="validationContext">
                <b-form-group
                  id="input-group-7"
                  label="Possui alguma deficiência?"
                  label-for="hasDeficiency"
                  class="mb-3"
                >
                  <b-form-select
                    id="hasDeficiency"
                    v-model="form.hasDeficiency"
                    :options="yesNo.filter(x => x.value === 0)"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-7-live-feedback"
                    @input="rulesToRegister()"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="undefined" disabled>-- Selecione --</b-form-select-option>
                    </template>
                  </b-form-select>
                  <b-form-invalid-feedback id="input-7-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-6 sm-12">
              <ValidationProvider name="Tipo da deficiência" rules="required_if:deficiency,1" v-slot="validationContext">
                <b-form-group
                  id="input-group-24"
                  label="Tipo de deficiência"
                  label-for="deficiencyType"
                  class="mb-3"
                  v-if="showDeficiency"
                >
                  <b-form-select
                    id="deficiencyType"
                    v-model="form.deficiencyTypeId"
                    :options="deficiencyTypes"
                    text-field="name"
                    value-field="id"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-24-live-feedback"
                    @input="setDeficiencyTypeId(form.deficiencyTypeId)"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="undefined" disabled>-- Selecione --</b-form-select-option>
                    </template>
                  </b-form-select>
                  <b-form-invalid-feedback id="input-24-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col cols="md-6 sm-12">
              <ValidationProvider name="CID" rules="required_if:deficiency,1" v-slot="validationContext">
                <b-form-group
                  id="input-group-8"
                  label="Qual o CID?"
                  label-for="cid"
                  class="mb-3"
                  v-if="showDeficiency"
                >
                  <b-skeleton-wrapper :loading="loading">
                    <template #loading>
                      <b-skeleton></b-skeleton>
                    </template>
                    <b-input-group>
                      <b-form-input
                        id="cid"
                        v-model="form.cid"
                        type="search"
                        v-mask="['A###']"
                        placeholder="Pesquise o CID ex.: F840"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-8-live-feedback"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button
                          variant="success"
                          @click="fetchCid(form.cid)"
                          v-b-tooltip.hover
                          title="Pesquise o CID e a deficiência será autocompletada"
                        >
                          <b-icon icon="search"></b-icon>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <b-form-invalid-feedback id="input-8-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-skeleton-wrapper>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-6 sm-12">
              <ValidationProvider name="Descrição da deficiência" rules="required_if:deficiency,1" v-slot="validationContext">
                <b-form-group
                  id="input-group-9"
                  label="Qual deficiência?"
                  label-for="cidDescription"
                  class="mb-3"
                  v-if="showDeficiency"
                >
                  <b-skeleton-wrapper :loading="loading">
                    <template #loading>
                      <b-skeleton></b-skeleton>
                      <b-skeleton></b-skeleton>
                      <b-skeleton></b-skeleton>
                    </template>
                    <b-form-textarea
                      id="cidDescription"
                      v-model="form.cidDescription"
                      placeholder="Descrição do CID"
                      rows="3"
                      max-rows="6"
                      class="mt-3"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-9-live-feedback"
                      disabled
                      style="background-color: white;"
                    ></b-form-textarea>
                    <b-form-invalid-feedback id="input-9-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-skeleton-wrapper>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>

      <ValidationObserver ref="observer2">
        <b-form>
          <b-row>
            <b-col cols="md-6 sm-12">
              <ValidationProvider name="Local de Treinamento" rules="required" v-slot="validationContext">
                <b-form-group
                  id="input-group-22"
                  label="Local de treinamento"
                  label-for="localTraining"
                  class="mb-3"
                  v-if="showModalityLocal"
                >
                  <b-skeleton-wrapper :loading="loading">
                    <template #loading>
                      <b-skeleton></b-skeleton>
                    </template>
                    <b-form-select
                      id="localTraining"
                      v-model="modalityLocalTraining.localTrainingId"
                      :options="localTrainings"
                      text-field="localTrainingName"
                      value-field="localTrainingId"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-22-live-feedback"
                      @input="onSelectLocalTraining(modalityLocalTraining.localTrainingName), onValidatePresetClass()"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="undefined" disabled>-- Selecione --</b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback id="input-22-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-skeleton-wrapper>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col cols="md-6 sm-12">
              <ValidationProvider name="Modalidade" rules="required" v-slot="validationContext">
                <b-form-group
                  id="input-group-23"
                  label="Modalidade"
                  label-for="modalitity"
                  class="mb-3"
                  v-if="showModalityLocal"
                >
                  <b-skeleton-wrapper :loading="loading">
                    <template #loading>
                      <b-skeleton></b-skeleton>
                    </template>
                    <b-form-select
                      id="modalitity"
                      v-model="modalityLocalTraining.modalityId"
                      :options="modalities"
                      text-field="modalityName"
                      value-field="modalityId"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-23-live-feedback"
                      @input="onSelectModality(modalityLocalTraining.modalityName), onValidatePresetClass()"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="undefined" disabled>-- Selecione --</b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback id="input-23-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-skeleton-wrapper>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>

      <ValidationObserver ref="observer3" v-if="isNeedValidatePresetClass">
        <b-form @submit.stop.prevent>
          <b-row>
            <b-col cols="md-12 sm-12">
              <ValidationProvider name="Turma" rules="required" v-slot="validationContext">
                <b-form-group
                  id="input-group-24"
                  label="Turma"
                  label-for="presetClass"
                  class="mb-3"
                  v-if="isNeedValidatePresetClass"
                >
                  <b-skeleton-wrapper :loading="loading">
                    <template #loading>
                      <b-skeleton></b-skeleton>
                    </template>
                    <b-form-select
                      id="presetClass"
                      v-model="modalityLocalTraining.presetClass"
                      :options="optionsPresetClass"
                      text-field="text"
                      value-field="text"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-23-live-feedback"
                      @input="checkHasVacancies(modalityLocalTraining.presetClass)"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="undefined" disabled>-- Selecione --</b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback id="input-23-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-skeleton-wrapper>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>

      <div class="d-flex justify-content-end" v-if="showModalityLocal">
        <b-button
          pill
          variant="info"
          class="mt-3"
          @click="clearModalityLocalTraining"
        >
          LIMPAR
        </b-button>
        <b-button
          pill
          variant="success"
          class="mt-3 ml-2"
          @click="validateAddBtn"
        >
          ADICIONAR
        </b-button>
      </div>

      <TableList
        :items="modalitiesLocals"
        :fields="fields"
        :emptyText="emptyText"
        @remove="removeModalityLocalTraining"
        v-if="showModalityLocal"
      />
    </b-jumbotron>

    <BackNextButton
      :back="back"
      :next="next"
    />
  </b-container>
</template>

<script>
import PageTitle from '@/components/pageTitle/PageTitle.vue'
import BackNextButton from '@/components/backNextButton/BackNextButton.vue'
import TableList from '@/components/tableList/TableList.vue'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { mixin } from '@/shared/mixins'
import ModalityLocalTrainingRepository from '@/shared/http/repositories/socialProject/modalityLocalTraining'

export default {
  name: 'ModalityLocalTraining',

  components: {
    [PageTitle.name]: PageTitle,
    [TableList.name]: TableList,
    [BackNextButton.name]: BackNextButton
  },

  mixins: [mixin],

  data: () => ({
    back: 'Dashboard',
    next: 'PersonalData',
    title: 'Local e Modalidade',
    emptyText: 'Nenhuma modalidade foi adicionada',
    fields: [
      {
        key: 'modalityName',
        label: 'Modalidade'
      },
      {
        key: 'localTrainingName',
        label: 'Local de treinamento'
      },
      {
        key: 'presetClass',
        label: 'Turma'
      },
      {
        key: 'actions',
        label: 'Ações'
      }
    ]
  }),

  computed: {
    ...mapState('modalityLocalTrainingModule', {
      modalityLocalTraining: 'modalityLocalTraining',
      modalitiesLocalTrainings: 'modalitiesLocalTrainings',
      localTrainings: 'localTrainings',
      modalities: 'modalities',
      modalitiesLocals: 'modalitiesLocals',
      isNeedValidatePresetClass: 'isNeedValidatePresetClass',
      optionsPresetClass: 'optionsPresetClass'
    }),

    ...mapState('personalDataModule', {
      form: 'personalData',
      yesNo: 'yesNo',
      deficiencyTypes: 'deficiencyTypes',
      schoolTerms: 'schoolTerms'
    }),

    ...mapState('commonModule', [
      'loading'
    ]),

    ...mapGetters({
      modalityLocalTraining: 'modalityLocalTrainingModule/modalityLocalTraining',
      age: 'personalDataModule/age',
      companyId: 'personalDataModule/companyId'
    }),

    showDeficiency() {
      if (this.form.hasDeficiency === 1) {
        return true
      }

      return false
    },

    showModalityLocal() {
      if (this.form.birthDate && this.form.hasDeficiency != undefined && this.form.schoolTerm != undefined) {
        return true
      }

      return false
    }
  },

  created() {
    this.fetchModalitiesLocalTrainings()
    this.fetchDeficiencyTypes(this.companyId)
  },

  methods: {
    ...mapMutations({
      clearModalityLocalTraining: 'modalityLocalTrainingModule/CLEAR_MODALITY_LOCALTRAINING',
      removeModalityLocal: 'modalityLocalTrainingModule/REMOVE_MODALITY_LOCAL',
      clearModalitiesLocals: 'modalityLocalTrainingModule/CLEAR_MODALITIES_LOCALS',
      clearOptionsPresetClass: 'modalityLocalTrainingModule/CLEAR_OPTIONS_PRESET_CLASS',
      pushModalityLocal: 'modalityLocalTrainingModule/PUSH_MODALITY_LOCAL',
      showLoading: 'commonModule/SHOW_LOADING',
      pushMLFiltered: 'modalityLocalTrainingModule/PUSH_ML_FILTERED',
      clearMLFiltered: 'modalityLocalTrainingModule/CLEAR_ML_FILTERED',
      setDeficiencyTypeId: 'personalDataModule/SET_DEFICIENCY_TYPE_ID',
      clearDeficiencyFields: 'personalDataModule/CLEAR_DEFICIENCY_FIELDS',
      setSchoolTerm: 'personalDataModule/SET_SCHOOL_TERM'
    }),

    ...mapActions({
      fetchModalitiesLocalTrainings: 'modalityLocalTrainingModule/fetchModalitiesLocalTrainings',
      onSelectLocalTraining: 'modalityLocalTrainingModule/onSelectLocalTraining',
      onClearModalityAndLocalTrainingOptions: 'modalityLocalTrainingModule/onClearModalityAndLocalTrainingOptions',
      onSelectModality: 'modalityLocalTrainingModule/onSelectModality',
      onFilteredOptionsPresetClass: 'modalityLocalTrainingModule/onFilteredOptionsPresetClass',
    }),

    ...mapActions('personalDataModule', {
      setAge: (dispatch, birthDate) => dispatch('setAge', birthDate),

      fetchCid: (dispatch, cidCode) => {
        if (!cidCode) return

        return dispatch('fetchCid', cidCode)
      },

      fetchDeficiencyTypes: (dispatch, companyId) => dispatch('fetchDeficiencyTypes', companyId)
    }),

    onClearPresetClassAndModality () {
      if (!this.isNeedValidatePresetClass) return

      this.clearModalityLocalTraining()
      this.clearOptionsPresetClass()
    },

    clearForm() {
      this.clearModalityLocalTraining()
      this.onClearPresetClassAndModality()

      this.$nextTick(() => {
        this.$refs.observer2.reset()
        if (this.isNeedValidatePresetClass) {
          this.$refs.observer3.reset()
        }
      })
    },

    clearDeficiency() {
      this.form.cid = null
      this.form.cidDescription = null
      this.form.deficiencyTypeId = undefined

      this.clearModalitiesLocals()
      this.clearDeficiencyFields()
      this.$nextTick(() => {
        this.$refs.observer.reset()
      })
    },

    onValidatePresetClass() {
      if (this.isNeedValidatePresetClass && !this.modalityLocalTraining.modalityId || !this.modalityLocalTraining.localTrainingId){
        return
      }

      this.onFilteredOptionsPresetClass(this.form.schoolTerm)
    },

    checkHasVacancies(presetChoosed) {
      if (!presetChoosed || !this.isNeedValidatePresetClass) return

      const entity = {
        presetChoosed: presetChoosed,
        totalVacancies: this.optionsPresetClass.find(x => x.text == presetChoosed).numberOfVacancies
      }

      ModalityLocalTrainingRepository.CheckVacancies(entity)
        .then(res => {
          if (!res.data.data) return Promise.reject()

          if (!res.data.data.hasVacancieAvailable) {
             this.$bvToast.toast('Esta turma já atingiu o limite máximo de cadastro.', {
              title: 'TURMA CHEIA',
              variant: 'danger',
              autoHideDelay: 4000
            })

            this.modalityLocalTraining.presetClass = null
            return
          }

          return Promise.resolve()
        })
        .catch(err => {
          this.$bvToast.toast('Ocorreu um erro ao verificar a quantidade de vagas.', {
              title: err,
              variant: 'danger',
              autoHideDelay: 2000
            })
        })
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },

    validateAddBtn() {
      if (this.isNeedValidatePresetClass && !this.modalityLocalTraining.presetClass) {
        this.$refs.observer3.validate()
        .then(valid => {
          if (!valid) {
            this.$bvToast.toast('Escolha uma turma junto os horários.', {
              title: 'Erro',
              variant: 'danger',
              autoHideDelay: 2000
            })
          }
        })
        return
      }

      this.$refs.observer2.validate()
        .then(valid => {
          if(!valid) {
            this.$bvToast.toast('Preencha todos os campos para adicionar uma modalidade.', {
              title: 'Erro',
              variant: 'danger',
              autoHideDelay: 2000
            })

            return
          }

          this.addModalityByLocal()
        })
    },

    addModalityByLocal() {
      const modalityLocal = this.modalityLocalTraining
      let hasModalityLocal = this.modalitiesLocals.some(x =>
        x.modalityId === modalityLocal.modalityId &&
        x.localTrainingId === modalityLocal.localTrainingId &&
        x.presetClass === modalityLocal.presetClass
      )

      if(hasModalityLocal) {
        this.clearForm()
        return this.$bvToast.toast('Você já escolheu este local e modalidade.', {
                  title: 'Aviso',
                  variant: 'warning',
                  autoHideDelay: 2000
                })
      }
      const { modalityId, localTrainingId, presetClass } = modalityLocal
      this.pushModalityLocal({ modalityId, localTrainingId, presetClass })
      this.clearForm()
    },

    removeModalityLocalTraining(index) {
      this.removeModalityLocal(index)
    },

    rulesToRegister() {
      if (!this.form.birthDate || this.form.hasDeficiency == undefined || this.form.schoolTerm == undefined) return

      // REGRA ALTERADA DE IDADE PARA ANO DE NASCIMENTO
      const year = new Date(this.form.birthDate).getFullYear()

      if(this.form.hasDeficiency) {
        return this.deficiencyRules()
      }

      return this.defaultRules(year)
    },

    defaultRules(year) {
      this.clearMLFiltered()
      let arrFilter = []

      /*
      if (this.age < 5 || this.age > 14) {
        return this.$bvToast.toast(`Idade ${this.age} anos não permitida.`,
          {
            title: 'Aviso',
            variant: 'warning',
            autoHideDelay: 2000
          })
      }
      */

      // NASCIDOS ENTRE A DATA NÃO É PERMITIDO
      if (year < 2012 || year > 2015) {
        this.modalityLocalTraining.localTrainingId = null
        this.modalityLocalTraining.modalityId = null
        this.onClearModalityAndLocalTrainingOptions()
        // limpar options preset

        return this.$bvToast.toast(`Ano de nascimento ${year} não é permitido.`,
          {
            title: 'Aviso',
            variant: 'warning',
            autoHideDelay: 3000
          })
      }

      /************************************************************************************************************************/

      // ARENA MANHÃ E TARDE - INFORMATICA BASICA
      if ((year >= 2012 && year <= 2015) && this.form.schoolTerm === 0 || this.form.schoolTerm === 1) {
        arrFilter = this.modalitiesLocalTrainings.filter(x => (x.modalityName === 'INFORMATICA BASICA') &&
          x.localTrainingName === 'ARENA')
        this.pushMLFiltered(arrFilter)
        this.onSelectModality()
        this.onSelectLocalTraining()
      }

      // ARENA MANHÃ E TARDE - ROBOTICA INFANTIL
      if ((year >= 2012 && year <= 2015) && this.form.schoolTerm === 0 || this.form.schoolTerm === 1) {
        arrFilter = this.modalitiesLocalTrainings.filter(x => (x.modalityName === 'ROBOTICA INFANTIL') &&
          x.localTrainingName === 'ARENA')
        this.pushMLFiltered(arrFilter)
        this.onSelectModality()
        this.onSelectLocalTraining()
      }

      /************************************************************************************************************************/

      // HÍPICA - NATAÇÃO E POLO 2 PERÍODOS
      // if ((this.age >= 5 && this.age <= 9) && this.form.schoolTerm === 0 || this.form.schoolTerm === 1) {
      //   arrFilter = this.modalitiesLocalTrainings.filter(x => (x.modalityName === 'POLO' || x.modalityName === 'NATAÇÃO') &&
      //     x.localTrainingName === 'HIPICA')
      //   this.pushMLFiltered(arrFilter)
      //   this.onSelectModality()
      //   this.onSelectLocalTraining()
      // }

      // HÍPICA - NATAÇÃO E POLO TARDE 2014 A 2018
      
      // if ((year >= 2015 && year <= 2018) && this.form.schoolTerm === 1) {
      //   arrFilter = this.modalitiesLocalTrainings.filter(x => (x.modalityName === 'POLO' || x.modalityName === 'NATAÇÃO') &&
      //     x.localTrainingName === 'HIPICA')
      //   this.pushMLFiltered(arrFilter)
      //   this.onSelectModality()
      //   this.onSelectLocalTraining()
      // }

      /************************************************************************************************************************/

      // CENTRO - POLO 2 PERÍODOS
      // if ((this.age >= 5 && this.age <= 9) && this.form.schoolTerm === 0 || this.form.schoolTerm === 1) {
      //   arrFilter = this.modalitiesLocalTrainings.filter(x => x.modalityName === 'POLO' && x.localTrainingName === 'CENTRO')
      //   this.pushMLFiltered(arrFilter)
      //   this.onSelectModality()
      //   this.onSelectLocalTraining()
      // }

      // CENTRO - POLO MANHÃ  2014 A 2017
      // if ((year >= 2014 && year <= 2017) && this.form.schoolTerm === 1) {
      //   arrFilter = this.modalitiesLocalTrainings.filter(x => x.modalityName === 'POLO' && x.localTrainingName === 'CENTRO')
      //   this.pushMLFiltered(arrFilter)
      //   this.onSelectModality()
      //   this.onSelectLocalTraining()
      // }

      /************************************************************************************************************************/

      // BTC - POLO MANHÃ
      // if ((this.age >= 5 && this.age <= 9) && this.form.schoolTerm === 0) {
      //   arrFilter = this.modalitiesLocalTrainings.filter(x => x.modalityName === 'POLO' && x.localTrainingName === 'BTC')
      //   this.pushMLFiltered(arrFilter)
      //   this.onSelectModality()
      //   this.onSelectLocalTraining()
      // }

      /************************************************************************************************************************/

      // ADPM - NATAÇÃO 2 PERÍODOS
      // if ((this.age >= 5 && this.age <= 9) && this.form.schoolTerm === 0 || this.form.schoolTerm === 1) {
      //   arrFilter = this.modalitiesLocalTrainings.filter(x => x.modalityName === 'NATAÇÃO' && x.localTrainingName === 'ADPM')
      //   this.pushMLFiltered(arrFilter)
      //   this.onSelectModality()
      //   this.onSelectLocalTraining()
      // }

      // ADPM - NATAÇÃO E POLO 2 PERÍODOS 2014 A 2018
      // if ((year >= 2014 && year <= 2018) && this.form.schoolTerm === 0 || this.form.schoolTerm === 1) {
      //   arrFilter = this.modalitiesLocalTrainings.filter(x => (x.modalityName === 'NATAÇÃO' || x.modalityName == 'POLO') &&
      //     x.localTrainingName === 'ADPM')
      //   this.pushMLFiltered(arrFilter)
      //   this.onSelectModality()
      //   this.onSelectLocalTraining()
      // }

      // ADPM - POLO TARDE
      // if ((this.age >= 5 && this.age <= 9) && this.form.schoolTerm === 1) {
      //   arrFilter = this.modalitiesLocalTrainings.filter(x => x.modalityName === 'POLO' && x.localTrainingName === 'ADPM')
      //   this.pushMLFiltered(arrFilter)
      //   this.onSelectModality()
      //   this.onSelectLocalTraining()
      // }

      /************************************************************************************************************************/

      // ADPM - ATLETISMO
      // if (this.age >= 7 && this.age <= 14) {
      //   arrFilter = this.modalitiesLocalTrainings.filter(x => x.modalityName === 'ATLETISMO' &&
      //     x.localTrainingName === 'ADPM')

      //   this.pushMLFiltered(arrFilter)
      //   this.onSelectModality()
      //   this.onSelectLocalTraining()
      // }

      /************************************************************************************************************************/

      // CAMPO ORIENTE - ATLETISMO
      // if (this.age >= 6 && this.age <= 17) {
      //   arrFilter = this.modalitiesLocalTrainings.filter(x => x.modalityName === 'ATLETISMO' &&
      //     x.localTrainingName === 'CAMPO DO ORIENTE')

      //   this.pushMLFiltered(arrFilter)
      //   this.onSelectModality()
      //   this.onSelectLocalTraining()
      // }

      //  CAMPO ORIENTE - ATLETISMO 2 PERÍODOS
      // if ((year >= 2009 && year <= 2017) && this.form.schoolTerm === 0 || this.form.schoolTerm === 1) {
      //   arrFilter = this.modalitiesLocalTrainings.filter(x => x.modalityName === 'ATLETISMO' &&
      //     x.localTrainingName === 'CAMPO DO ORIENTE')
      //   this.pushMLFiltered(arrFilter)
      //   this.onSelectModality()
      //   this.onSelectLocalTraining()
      // }

      /************************************************************************************************************************/

      // MÚSICA
      // if (this.age >= 6 && this.age <= 12) {
      //   arrFilter = this.modalitiesLocalTrainings.filter(x => x.modalityName === 'ORQUESTRA FILARMÔNICA' &&
      //     x.localTrainingName === 'PREVE')

      //   this.pushMLFiltered(arrFilter)
      //   this.onSelectModality()
      //   this.onSelectLocalTraining()
      // }
    },

    deficiencyRules() {
      this.clearMLFiltered()
      let arrFilter = []

      if (this.age < 8 || this.age > 25) {
        return this.$bvToast.toast(`Idade ${this.age} anos não permitida.`, {
                title: 'Aviso',
                variant: 'warning',
                autoHideDelay: 2000
              })
      }

      arrFilter = this.modalitiesLocalTrainings.filter(x => x.modalityName === 'NATAÇÃO PCD')
      this.pushMLFiltered(arrFilter)
      this.onSelectModality()
      this.onSelectLocalTraining()
    }
  },

  beforeRouteEnter(to, from, next) {
    if(from.name === 'Dashboard') {
      return next(vm => {
        vm.clearForm()
      })
    }

    if(from.name === 'PersonalData') {
      return next(vm => {
        vm.modalitiesLocals
        vm.clearForm()
      })
    }

    return next(false)
  },

  beforeRouteLeave(to, from, next) {
    if(to.name === 'PersonalData') {

      if (!this.form.birthDate || !this.form.hasDeficiency) {
        this.$refs.observer.validate()
          .then(valid => {
            if(!valid) {
              this.$bvToast.toast('Preencha a data de nascimento, período escolar e o(s) campo(s) de deficiência.', {
                title: 'Erro',
                variant: 'danger',
                autoHideDelay: 2000
              })
            }
          })

        next(false)
      }

      if(!this.modalitiesLocals.length) {
        this.$refs.observer2.validate()
          .then(() => {
            this.$bvToast.toast('Adicione uma modalidade por um local de treinamento.', {
              title: 'Erro',
              variant: 'danger',
              autoHideDelay: 2000
            })
          })

        return next(false)
      }
    }

    if(to.name === 'Dashboard' || to.name === 'Infos' || to.name === 'NotFound') {
      this.clearState()
      return next()
    }

    return next()
  },

  watch: {
    'form.hasDeficiency'(val) {
      if(val === 0) {
        this.clearDeficiency()

        this.$nextTick(() => {
          this.$refs.observer.reset()
        })

        return
      }

      return this.clearDeficiency()
    }
  }
}
</script>

<style lang="scss">
  label::after {
    content: ' *';
    color: red;
  }
</style>
